.headerform {
  display: flex;
  font-family: ui-sans-serif;
  color: orange;
  font-style: oblique;
  justify-content: center;
  font-size: 2rem;
height: 150px;

}
.headerform>h1{
  display: flex;
  position: absolute;
  font-size: larger;
  margin: 0%;

}
.notfound {
  color: #606F7B;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
}

.contenedormayor {
  display: flex;
  justify-content: center;
  /* border: 1px solid green; */
  flex-direction: column;
  background: linear-gradient(to right, #222629, #3D4852, #1d252b);

}

form,
input,
selector,
option ,textarea{
  font-family: fantasy;
  text-align: center;
}

input ,textarea{
  background-color: rgb(202, 202, 202);
}

.contenedor {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  color: #ffffff;
  margin: 0.3rem;
}

.form-group {
  display: flex;
  justify-content: center;
  justify-items: center;
     align-items: center;
       flex-wrap: wrap;
}
.containform{
  display: flex;
  width: 100%;
  justify-content: center;
    
}
.form-g{
  font-size: large;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
align-items: stretch;  
}
.form-g:nth-child(1)>input,.form-g:nth-child(6)>input{
width: 95%;
}
.form-g:nth-child(2){
  flex-direction: row;
  justify-content: center;
}
.form-g:nth-child(4)>div,.form-g:nth-child(5)>div{
  display: flex;
}
.form-g:nth-child(4)>div>input,.form-g:nth-child(5)>div>input{
  width: 50%;
}



body {font-size: 100%}

h1 {font-size: 3em;}

@media screen and (max-width: 64em) {
h1 {
    font-size: 2.5em;
  }
}

@media screen and (max-width: 50em) {
h1 {
    font-size: 2em;
  }
}

@media screen and (max-width: 30em){
h1 {
    font-sitze: 1.5em;
  }
}
input ,textarea{
  font-size: 16pt;
  color: rgb(54, 47, 47);
}

label,
input,
button,
select,
option ,textarea{
  padding: 0.5rem;
  margin: 0.3rem;
  border-radius: 1rem;
  box-shadow: inset 0px 0px 10px 0 rgba(20, 20, 20, 0.2);
}

button:hover,
select:hover,
option:hover {
  color: rgba(255, 255, 255, 0.733);
  transition: 1s;
  max-width: 15rem;
  cursor: pointer;
  border-radius: 30%;
  box-shadow: 8px 8px 3px 3px rgba(0, 0, 0, 0.329);
}

button:active,option:active,select:active{
  box-shadow: none;
  box-shadow: 5px 2px 2px 2px rgba(255, 7, 7, 0.521);
}
.card:hover {

  transition: 0.3s;
  font-size: larger;
}

.panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0 0 20% 20%;
  color: rgba(255, 255, 255, 0.767);
  background: linear-gradient(to right, #0e1011, #3D4852, #0e1011);
}

button,
select,
option {
  background: none;
  border: none;
  border-bottom: #000000 1px solid;
  color: rgba(255, 255, 255, 0.836);
  padding: 0.5rem 0.5rem;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  opacity: 1;
max-width: 15rem;
  font-family: fantasy;
}
.card>h3{
  margin: 5px;
  text-align: start;
}
/*form ******************** img*/
.imgcard_form {
  object-fit: cover;
 width: 100%;
  height: 200px;

}
.contain_img{
  overflow: hidden;
    height: 150px;
    
    position: initial;
  }

  /*****************************/
.imgcard{
  object-fit: contain;
  position: relative;
  bottom: 1%;
  width: 400px;
  height: auto;
}
.contain_img_card {overflow: hidden;
  width: 100%;
  height: 230px
  }
.imgcardd{
  object-fit: cover;
  position: relative;
  bottom: 1%;
  width: 100%;
  height: auto;
}
.contain_img_cardd{
  overflow: hidden;
  width: 100%;
  height: auto;
  max-width: 600px;
  min-width: 350px;
  border-radius: 20px;
  max-height: 800px;
}


  


.imglanding {
  height: 100%;
  margin: 0%;
}
.container_img_landing{
display: flex
;
justify-content: center;
  overflow: hidden;
height: 100vh;
margin: 0%;
align-content:center;

}
.landing {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #070808, #646363, #101011,#555353,#000000);
  
}

.b-landing:hover {
  font-size: 3rem;
  background: linear-gradient(to right, #0c0e0f, #000000, #0f1113);
  transition: 3s;
  
}
.formlanding {
position: fixed;
right: 50%;
top: 50%;
  justify-content: center;
  margin: 0%  ;
  }
.b-landing {

  font-size: 3rem;
  border-radius: 20%;
  width: 220px;
  height: 100px;
  background: linear-gradient(to right, #1f272e, #040405, #1f272e);
  opacity: 0.8;
  margin-left: 50%;

}
.container_perros{
  display: flex;
  width: 100%;
  justify-content: center;
}
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* border: 5px solid pink; */
  padding-top: 2rem;
  padding-bottom: 5rem;
  justify-content: center;

  gap: 20px;
}

.form {
  width: 100%;
  border-radius: 7%;
  line-height: 24pt;
  letter-spacing: 0.5px;
  height: fit-content;
  object-fit: cover;
  font-size: 1rem;
  text-decoration: dotted;
  text-align: center;
  color: white;
  margin: 0px;
  padding: 0%;
  box-shadow: inset 30px 10px 30px 30px rgba(20, 20, 20, 0.534);
  font-style: italic;
  font-size: large;
}
.cardDetail{
  width: 100%;
  border-radius: 7%;
  line-height: 24pt;
  letter-spacing: 0.5px;
  height: fit-content;
  object-fit: cover;
  font-size: 1rem;
  text-decoration: dotted;
  text-align: center;
  color: white;
  margin: 0px;
  padding: 0%;
  font-style: italic;
  font-size: large;
  display: flex;
  flex-wrap: wrap;
  /**/
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* border: 5px solid pink; */
  padding-top: 2rem;
  padding-bottom: 5rem;
  justify-content: center;

  gap: 20px;
}
.card,
.cardd {

  border-radius: 5%;
  line-height: 13pt;
  letter-spacing: 0px;
  width: 360px;
  height: 300px;
  object-fit: contain;
  font-size: 1rem;
  text-decoration: dotted;
  text-align: center;
  background: linear-gradient(to bottom, #1e1e22, #1c1e2c);
  color: rgba(255, 255, 255, 0.849);
  border: 1px solid #1e1e22;
  box-shadow: inset 30px 10px 30px 30px rgb(20, 20, 20);
  box-shadow: 8px 8px 3px 3px rgba(0, 0, 0, 0.329);
  
}
.card:hover{

  box-shadow: 8px 8px 9px 9px rgba(0, 0, 0, 0.329);
}
.cardd {
  height: fit-content;
}

.b-home {
  color: black;
  background-color: orange;
  width: 200px;
}

.b-home:hover {
  width: 200px;
  color: black;
  background-color: orangered;
}

.navlink {
  color: rgba(255, 174, 0, 0.644);
  font-size:large;
  padding: 0.5rem;
  border-radius: 2rem;
  font-style: oblique;
}
/*panell*******************/
.imagenpanel {
  width: auto;
  height: 100px;

}
.container_img_panel{
  overflow: hidden;
  height: 70px;
}
/*******************************/
.formdetail {
  display: flex;
  height: 8vh;

}

.form {
  display: flex;
  align-items: flex-end;
  text-align: center;
  
  width: 80%;
  max-width: 600px;
  height: fit-content;
  padding: 3%;
  flex-wrap: wrap;
  justify-content: center;
   background-color: #243241;
}
form{
  display: flex;
  flex-direction: column;
}
.panel2{
 display: flex;
 justify-content: center;
}
.form-create-dog{
  display: flex;
  align-content: center;
  align-items: center;
  
}
.b-create-dog {
  justify-content: center;
  align-content:center; 
  background: none;
  background-color: rgba(255, 166, 0, 0.678);
  opacity: 0.9;
  color: whitesmoke;
}

.b-create-dog:hover {
}

select option {
  background-color: #374049;
  color: whitesmoke;
}
.span_dogdetail{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
text-align: center;

}
 @media only screen and (max-width: 480px) {
  
  .panel2{
    
   }
   input,button,select,option{
    width: 90px;
    height: 40px;
    font-size: small;
    padding: 0%;
    text-align: center;

   }
   input{
    min-width: 130px;
   }
   .imagenpanel {
    width: auto;
    height: 70px;
  
  }
  .container_img_panel{
    overflow: hidden;
    height: 50px;
  }
} 
@media only screen and (min-width: 480px) and (max-width:786px) {
  input,button,select,option{
    width: 120px;
    height: 40px;
    font-size: medium;
    padding: 0%;
    text-align: center;
    
    
    
  }
  button,select,option{
  background: none;
  }
}